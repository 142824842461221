<template>
<div id="topnav"></div>
    <section class="register-area" v-if="!isLoading">
      <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
          <div class="register-image">
            <img src="@/assets/img/register-bg.jpg" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
          <div class="register-content">
            <div class="d-table">
              <div class="d-table-cell">
                <div class="register-form">
                  <div class="logo">
                    <router-link :to="{ name: 'Home' }"><img  class="logo" src="@/assets/img/logo.png" alt="image" /></router-link>
                  </div>
                  <div v-if="user.status == 2">
                    <h3>You are a Trainer Already</h3>
                    <router-link :to="{ name: 'TrainerDashboard'}" class="btn default-btn"><span>To Dashboard</span></router-link>
                    <p>Are you a Trainer a company? <router-link :to="{ name: 'TrainerRegisterCompany'}" class="btn text-default"><span>Register Your Company</span></router-link></p>
                  </div>
                  <div v-else>
                    <h3>Share Your Knownledge to The World</h3>
                    <p>Get started in your path to being a trainer in our website by clicking button below, that will take you thourghout the steps</p>
                    <p>Become a Trainer? <button @click="registerTrainer()" class="btn text-default"><span>Become Now</span></button></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
     <div v-else>
      <Loading />
    </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading.vue'
export default {
  components: { Loading, },
  data(){
    return {
        values: [],
        errors:[],
        user:{},
        isLoading: true,
        submitting: false,
        token: localStorage.getItem('user_data'),
    }
  },
  methods:{
    registerTrainer(){
      this.submitting = true
      this.value = 'Please Wait...'
      axios.post('https://apitraining.vipawaworks.com/api/auth/user/change_role_status/2', this.form).then(response => {
        response.data
        this.$router.push(this.$route.query.redirect || { name: 'TrainerDashboard'})
      }).catch((error) => {
        this.errors = error.response.data.errors
        this.submitting = false
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
        document.title = `Trainer Register - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
    }   

}
</script>

<style>

</style>